import { useState, useCallback, useEffect } from "react";
import { URLS, EMAILJS_CONFIG } from "./constants";
import "./Home.css";
import emailjs from "emailjs-com";
import appStoreBadge from "./app-store-badge.svg";
import playStoreBadge from "./GetItOnGooglePlay_Badge_Web_color_English.png";
import appPreview from "./README.png";

const Home = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    subject: "",
  });
  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    subject: false,
  });
  const [loading, setLoading] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = useCallback(() => {
    const errors = {
      name: "",
      email: "",
      subject: "",
    };
    let isValid = true;

    if (!formData.name.trim() && touchedFields.name) {
      errors.name = "Emri është i detyrueshëm";
      isValid = false;
    }

    if (!formData.email.trim() && touchedFields.email) {
      errors.email = "Email-i është i detyrueshëm";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email) && touchedFields.email) {
      errors.email = "Email-i nuk është i vlefshëm";
      isValid = false;
    }

    if (!formData.subject.trim() && touchedFields.subject) {
      errors.subject = "Tema është e detyrueshme";
      isValid = false;
    }

    setFormErrors(errors);
    setIsFormValid(isValid);
  }, [formData, touchedFields]);

  useEffect(() => {
    validateForm();
  }, [formData, touchedFields, validateForm]);

  const handleChange = useCallback((e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleBlur = useCallback((e) => {
    const { id } = e.target;
    setTouchedFields((prev) => ({ ...prev, [id]: true }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouchedFields({
      name: true,
      email: true,
      subject: true,
    });
    if (!formData.email || !formData.name || !formData.subject) return;
    if (!isFormValid) return;

    setLoading(true);

    try {
      await emailjs.send(
        EMAILJS_CONFIG.SERVICE_ID,
        EMAILJS_CONFIG.TEMPLATE_ID,
        {
          name: formData.name,
          user_email: formData.email,
          message: formData.subject,
        },
        EMAILJS_CONFIG.USER_ID
      );

      setFormStatus({ type: "success", message: "Email u dërgua me sukses!" });
      setFormData({ name: "", email: "", subject: "" });
      setTouchedFields({ name: false, email: false, subject: false });
    } catch (err) {
      console.error(err);
      setFormStatus({
        type: "error",
        message: "Diqka shkoi keq. Ju lutemi provoni përsëri.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <section className="hero-section fade-in-up" aria-label="Welcome section">
        <div className="hero-content">
          <h1 className="hero-title">Mirë se vini në Fletushkë.</h1>
          <p className="hero-subtitle">
            Shkarkoni aplikacionin tonë për të përfituar nga të gjitha veçoritë
            e mrekullueshme që ofrojmë. Në dispozicion për iOS dhe Android.
          </p>
          <div
            className="store-buttons"
            role="group"
            aria-label="App store downloads"
          >
            <a
              href={URLS.APP_STORE}
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
              aria-label="Download on the App Store"
            >
              <img
                src={appStoreBadge || "/placeholder.svg"}
                alt="Download on the App Store"
                className="store-badge"
                loading="lazy"
              />
            </a>
            <a
              href={URLS.PLAY_STORE}
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
              aria-label="Get it on Google Play"
            >
              <img
                src={playStoreBadge || "/placeholder.svg"}
                alt="Get it on Google Play"
                className="store-badge"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="app-preview">
          <img
            src={appPreview || "/placeholder.svg"}
            alt="Fletushkë App Interface Preview"
            loading="lazy"
          />
        </div>
      </section>

      <div className="contact-form fade-in-up">
        <h2 className="form-title">Na kontaktoni</h2>
        <p className="form-subtitle">
          Na dërgoni një mesazh dhe ne do t'ju përgjigjemi sa më shpejt që të
          jetë e mundur.
        </p>

        {formStatus && (
          <div
            className={`form-status ${
              formStatus.type === "success"
                ? "form-status-success"
                : "form-status-error"
            }`}
            role="alert"
            aria-live="polite"
          >
            {formStatus.message}
          </div>
        )}

        <form onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <label htmlFor="name" className="form-label">
              Emri
            </label>
            <input
              id="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-input ${
                touchedFields.name && formErrors.name ? "input-error" : ""
              }`}
              placeholder="Shkruani emrin"
              required
              aria-required="true"
              aria-invalid={touchedFields.name && !!formErrors.name}
              aria-describedby="name-error"
            />
            {touchedFields.name && formErrors.name && (
              <span id="name-error" className="error-message">
                {formErrors.name}
              </span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-input ${
                touchedFields.email && formErrors.email ? "input-error" : ""
              }`}
              placeholder="Shkruani email"
              required
              aria-required="true"
              aria-invalid={touchedFields.email && !!formErrors.email}
              aria-describedby="email-error"
            />
            {touchedFields.email && formErrors.email && (
              <span id="email-error" className="error-message">
                {formErrors.email}
              </span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="subject" className="form-label">
              Tema
            </label>
            <textarea
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-input ${
                touchedFields.subject && formErrors.subject ? "input-error" : ""
              }`}
              placeholder="Shkruani mesazhin"
              rows="4"
              required
              aria-required="true"
              aria-invalid={touchedFields.subject && !!formErrors.subject}
              aria-describedby="subject-error"
            />
            {touchedFields.subject && formErrors.subject && (
              <span id="subject-error" className="error-message">
                {formErrors.subject}
              </span>
            )}
          </div>

          <button
            type="submit"
            disabled={
              loading ||
              !isFormValid ||
              !formData.email ||
              !formData.name ||
              !formData.subject
            }
            className="form-button"
            aria-busy={loading}
          >
            {loading ? "Duke dërguar..." : "Dërgo"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Home;
