import { useState, useEffect, useCallback } from "react";
import { URLS } from "./constants";
import "./Header.css";
import logo from "./asdf.png";
import appStoreBadge from "./app-store-badge.svg";
import playStoreBadge from "./GetItOnGooglePlay_Badge_Web_color_English.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleResize = useCallback(() => {
    const isMobileView = window.innerWidth <= 1024;
    setIsMobile(isMobileView);
    if (!isMobileView) setIsMenuOpen(false);
  }, []);

  const handleScroll = useCallback(() => {
    setHasScrolled(window.scrollY > 20);
  }, []);

  useEffect(() => {
    handleResize();
    handleScroll();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleResize, handleScroll]);

  const NavLinks = () => (
    <>
      <a href="/" className="nav-link" aria-label="Faqja Kryesore">
        Faqja Kryesore
      </a>
      <a href="/policy" className="nav-link" aria-label="Kushtet e perdorimit">
        Kushtet e perdorimit
      </a>
      <div className="store-buttons" role="group" aria-label="App store links">
        <a
          href={URLS.APP_STORE}
          target="_blank"
          rel="noopener noreferrer"
          className="store-link"
          aria-label="Download on the App Store"
        >
          <img
            src={appStoreBadge || "/placeholder.svg"}
            alt="Download on the App Store"
            className="store-badge"
            loading="lazy"
          />
        </a>
        <a
          href={URLS.PLAY_STORE}
          target="_blank"
          rel="noopener noreferrer"
          className="store-link"
          aria-label="Get it on Google Play"
        >
          <img
            src={playStoreBadge || "/placeholder.svg"}
            alt="Get it on Google Play"
            className="store-badge"
            style={{ width: "135px", height: "40px" }}
            loading="lazy"
          />
        </a>
      </div>
    </>
  );

  return (
    <header className={`header ${hasScrolled ? "scrolled" : ""}`}>
      <div className="header-container">
        <div className="logo-container">
          <a href="/" aria-label="Fletushkë Home">
            <img
              src={logo || "/placeholder.svg"}
              alt="Fletushkë Logo"
              className="logo"
              width="120"
              height="40"
            />
          </a>
        </div>
        {isMobile ? (
          <button
            className="menu-toggle"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-expanded={isMenuOpen}
            aria-label="Toggle menu"
          >
            <span className="sr-only">Menu</span>
            {isMenuOpen ? "✕" : "☰"}
          </button>
        ) : (
          <nav
            className="desktop-nav"
            role="navigation"
            aria-label="Main navigation"
          >
            <NavLinks />
          </nav>
        )}
      </div>
      {isMobile && (
        <nav
          className={`mobile-nav ${isMenuOpen ? "open" : ""}`}
          role="navigation"
          aria-label="Mobile navigation"
        >
          <NavLinks />
        </nav>
      )}
    </header>
  );
};

export default Header;
