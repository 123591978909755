export const URLS = {
  APP_STORE: "https://apps.apple.com/us/app/fletushkë/id6738890147",
  PLAY_STORE:
    "https://play.google.com/store/apps/details?id=com.iddowave.fletushka",
  EMAIL: "fletushka12@gmail.com",
};

export const EMAILJS_CONFIG = {
  SERVICE_ID: "service_j0cmwqo",
  TEMPLATE_ID: "template_ro7ch7f",
  USER_ID: "E6Z_55PueYFhhiGtL",
};

export const COLORS = {
  PRIMARY: "#EAB026",
  PRIMARY_DARK: "#C4901E",
};
