import type React from "react";
import { useEffect, useState } from "react";
import "./AppRedirectPopup.css";

interface AppRedirectPopupProps {
  brochureId: string;
  onClose: () => void;
  onBrowserOpen: () => void;
}

const AppRedirectPopup: React.FC<AppRedirectPopupProps> = ({
  brochureId,
  onClose,
  onBrowserOpen,
}) => {
  const [popupStage, setPopupStage] = useState<string>("0");

  useEffect(() => {
    const stage = sessionStorage.getItem("popupStage");
    if (stage) {
      setPopupStage(stage);
    }
  }, []);

  const openAppStore = () => {
    sessionStorage.removeItem("popupStage");
    sessionStorage.removeItem("sessionChecked");
    window.location.href =
      "https://apps.apple.com/us/app/fletushkë/id6738890147";
  };

  const openPlayStore = () => {
    sessionStorage.removeItem("popupStage");
    sessionStorage.removeItem("sessionChecked");
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.iddowave.fletushka";
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Hap Aplikacionin Fletushkë.</h2>
        {popupStage === "0" ? (
          <>
            <p className="modal-description">
              Duket se aplikacioni Fletushkë nuk u hap automatikisht. Si
              dëshironi të vazhdoni?
            </p>
            <div className="button-container">
              <button onClick={onBrowserOpen} className="modal-button">
                Hap në Browser
              </button>
              <button onClick={onClose} className="modal-button">
                Mbyll
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="modal-description">
              Për të parë këtë përmbajtje, ju lutemi instaloni aplikacionin
              Fletushkë. Si dëshironi të vazhdoni?
            </p>
            <div className="button-container">
              {isIOS ? (
                <button onClick={openAppStore} className="modal-button">
                  Hap në App Store
                </button>
              ) : (
                <button onClick={openPlayStore} className="modal-button">
                  Hap në Play Store
                </button>
              )}
              <button onClick={onClose} className="modal-button">
                Mbyll
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AppRedirectPopup;
