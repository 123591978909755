import React from "react";
import "./Policy.css";

const Policy = () => {
  return (
    <div className="policy-page">
      <div className="policy-container">
        <TermsAndConditions />
        <hr className="policy-divider" />
        <PrivacyPolicy />
      </div>
    </div>
  );
};

const TermsAndConditions = () => (
  <>
    <header className="policy-header">
      <h1 className="policy-title">Kushtet dhe Termat</h1>
      <p className="policy-subtitle">Data e Efektit: 01/12/2024</p>
    </header>
    <div className="policy-content">
      <p className="policy-text">
        Mirë se vini në Fletushkë! Duke përdorur aplikacionin tonë, ju pranoni
        këto Kushte dhe Terma. Ju lutemi, lexoni me kujdes.
      </p>
      <PolicySection
        title="1. Përmbledhje"
        content={
          <>
            <p className="policy-text">
              Aplikacioni Fletushkë i lejon përdoruesit të:
            </p>
            <ul className="policy-list">
              <li className="policy-list-item">
                Shikojnë dyqanet dhe fletushkat e tyre.
              </li>
              <li className="policy-list-item">
                Ndryshojnë qytetet për të eksploruar dyqane në ato qytete.
              </li>
              <li className="policy-list-item">
                Marrin njoftime për dyqanet me të cilat kanë ndërvepruar ose që
                u kanë pëlqyer.
              </li>
              <li className="policy-list-item">
                Nuk kërkohet llogari përdoruesi për të përdorur këtë aplikacion.
              </li>
            </ul>
          </>
        }
      />
      <PolicySection
        title="2. Pranimi i Kushteve"
        content="Duke aksesuar ose përdorur aplikacionin Fletushkë, ju pranoni këto Kushte. Nëse nuk i pranoni, nuk duhet të përdorni aplikacionin."
      />
      <PolicySection
        title="3. Përdorimi i Aplikacionit"
        content="Duhet të jeni të paktën 13 vjeç për të përdorur aplikacionin Fletushkë. Aplikacioni ofrohet vetëm për përdorim personal dhe jo-komercial. Ju pranoni të mos abuzoni me aplikacionin, duke përfshirë përpjekjet për të prishur funksionalitetin e tij ose për të aksesuar të dhëna pa autorizim."
      />
      <PolicySection
        title="4. Pronësia Intelektuale"
        content="I gjithë përmbajtja në aplikacion, duke përfshirë tekstin, imazhet dhe dizajnin, është pronë e Iddowave L.L.C ose licencuesve të tij. Nuk lejohet riprodhimi, shpërndarja ose përdorimi i kësaj përmbajtjeje pa leje."
      />
      <PolicySection
        title="5. Njoftimet"
        content="Duke përdorur aplikacionin, ju pranoni të merrni njoftime lidhur me dyqanet që ju kanë pëlqyer. Ju mund të menaxhoni këto njoftime në cilësimet e pajisjes suaj."
      />
      <PolicySection
        title="6. Përmbajtja e Palëve të Treta"
        content="Aplikacioni shfaq fletushka dhe përmbajtje të tjera të ofruara nga palë të treta. Aplikacionin Fletushkë nuk është përgjegjëse për saktësinë ose ligjshmërinë e kësaj përmbajtjeje."
      />
      <PolicySection
        title="7. Kufizimi i Përgjegjësisë"
        content="Aplikacionin Fletushkë ofrohet 'siç është' pa asnjë garanci. Ne nuk jemi përgjegjës për ndonjë dëm që lind nga përdorimi i aplikacionit."
      />
      <PolicySection
        title="8. Ndryshimet në Kushtet"
        content="Ne rezervojmë të drejtën të përditësojmë këto Kushte në çdo kohë. Vazhdimi i përdorimit të aplikacionit pas ndryshimeve nënkupton që i pranoni Kushtet e përditësuara."
      />
      <PolicySection
        title="9. Kontaktoni"
        content={
          <p className="policy-text">
            Nëse keni ndonjë pyetje, ju lutemi na kontaktoni në{" "}
            <a href="mailto:fletushka12@gmail.com" className="policy-link">
              fletushka12@gmail.com
            </a>
            .
          </p>
        }
      />
    </div>
  </>
);

const PrivacyPolicy = () => (
  <>
    <header className="policy-header">
      <h1 className="policy-title">Politika e Privatësisë</h1>
      <p className="policy-subtitle">Data e Efektit: 01/12/2024</p>
    </header>
    <div className="policy-content">
      <p className="policy-text">
        Aplikacionin Fletushkë është e angazhuar për të mbrojtur privatësinë
        tuaj. Kjo Politikë e Privatësisë shpjegon se si ne mbledhim, përdorim
        dhe mbrojmë informacionin tuaj.
      </p>
      <PolicySection
        title="1. Informacioni që Mbledhim"
        content={
          <>
            <p className="policy-text">
              Ne nuk mbledhim informacion personal si emra, email-e ose llogari.
              Megjithatë, mund të mbledhim:
            </p>
            <ul className="policy-list">
              <li className="policy-list-item">
                Informacioni i Pajisjes: Përfshin llojin e pajisjes, sistemin
                operativ dhe të dhënat e përdorimit të aplikacionit.
              </li>
              <li className="policy-list-item">
                Të dhënat e Vendndodhjes: Përdoren për të shfaqur dyqane dhe
                fletushka të rëndësishme për qytetin që keni zgjedhur.
              </li>
              <li className="policy-list-item">
                Preferencat e Njoftimeve: Për t'ju dërguar përditësime lidhur me
                dyqanet që ju pëlqejnë.
              </li>
            </ul>
          </>
        }
      />
      <PolicySection
        title="2. Si e Përdorim Informacionin Tuaj"
        content={
          <>
            <p className="policy-text">
              Ne përdorim të dhënat që mbledhim për të:
            </p>
            <ul className="policy-list">
              <li className="policy-list-item">
                Shfaqur dyqane dhe fletushka bazuar në qytetin që keni zgjedhur.
              </li>
              <li className="policy-list-item">
                Dërguar njoftime lidhur me dyqanet që ju pëlqejnë.
              </li>
              <li className="policy-list-item">
                Përmirësuar performancën dhe përvojën e përdoruesit në
                aplikacion.
              </li>
            </ul>
          </>
        }
      />
      <PolicySection
        title="3. Shkëmbimi i të Dhënave"
        content="Ne nuk shesim, shkëmbejmë ose japim me qira informacionin tuaj palëve të treta. Megjithatë, mund të ndajmë të dhëna të anonimizuara ose të agreguara me: Ofrues të analizave për të përmirësuar performancën e aplikacionit. Partnerët e dyqaneve për të kuptuar tendencat e përdorimit të aplikacionit."
      />
      <PolicySection
        title="4. Shërbimet e Palëve të Treta"
        content="Aplikacioni mund të shfaqë përmbajtje ose lidhje të ofruara nga palë të treta. Ne nuk jemi përgjegjës për praktikat e privatësisë së tyre dhe ju inkurajojmë të shqyrtoni politikat e tyre."
      />
      <PolicySection
        title="5. Ruajtja e të Dhënave"
        content="Ne ruajmë të dhënat vetëm për aq kohë sa është e nevojshme për të përmbushur qëllimet e përshkruara në këtë politikë."
      />
      <PolicySection
        title="6. Zgjedhjet Tuaja"
        content="Qasja në Vendndodhje: Ju mund ta çaktivizoni aksesin në vendndodhje në cilësimet e pajisjes tuaj. Njoftimet: Ju mund të menaxhoni ose çaktivizoni njoftimet përmes cilësimeve të pajisjes tuaj. Kërkesat për të Dhëna: Meqenëse ne nuk mbledhim të dhëna personale, nuk ka asgjë për të fshirë ose aksesuar."
      />
      <PolicySection
        title="7. Privatësia e Fëmijëve"
        content="Aplikacioni nuk është i destinuar për përdorues nën moshën 13 vjeç. Ne nuk mbledhim me vetëdije informacion nga fëmijët."
      />
      <PolicySection
        title="8. Ndryshimet në Këtë Politikë"
        content="Ne mund të përditësojmë këtë Politikë të Privatësisë herë pas here. Ne do t'ju njoftojmë për ndryshime të rëndësishme përmes aplikacionit ose mjeteve të tjera."
      />
      <PolicySection
        title="9. Kontaktoni"
        content={
          <p className="policy-text">
            Nëse keni pyetje rreth kësaj Politike të Privatësisë, ju lutemi na
            kontaktoni në{" "}
            <a href="mailto:fletushka12@gmail.com" className="policy-link">
              fletushka12@gmail.com
            </a>
            .
          </p>
        }
      />
    </div>
  </>
);

const PolicySection = ({ title, content }) => (
  <section className="policy-section">
    <h2 className="section-title">{title}</h2>
    {typeof content === "string" ? (
      <p className="policy-text">{content}</p>
    ) : (
      content
    )}
  </section>
);

export default Policy;
