import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Policy from "./Policy";
import { initializeApp } from "firebase/app";
import Header from "./Header";
import AppRedirectPopup from "./AppRedirectPopup";

const firebaseConfig = {
  apiKey: "AIzaSyBHMYShe2HTH4F2vGnb6dgnG-LY1xsbBcI",
  authDomain: "fletushkaweb.firebaseapp.com",
  projectId: "fletushkaweb",
  storageBucket: "fletushkaweb.firebasestorage.app",
  messagingSenderId: "834861036740",
  appId: "1:834861036740:web:a03a474d0172dde7d38591",
};

const app = initializeApp(firebaseConfig);

function isFacebookMessenger() {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes("fb_iab") ||
    userAgent.includes("fb4a") ||
    userAgent.includes("fbav")
  );
}

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [currentBrochureId, setCurrentBrochureId] = useState<string | null>(
    null
  );

  const closePopup = () => {
    setShowPopup(false);
    sessionStorage.removeItem("popupStage");
    sessionStorage.removeItem("sessionChecked");
  };

  const handleBrowserOpen = () => {
    window.location.href = `https://www.fletushke.com/brochure/${currentBrochureId}`;
  };

  useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/brochure[/]?([a-zA-Z0-9]+)/i);
    if (match) {
      const brochureId = match[1];
      const source =
        window.location.href.includes("www") && isFacebookMessenger();
      let sessionChecked;
      if (source) {
        sessionStorage.setItem("popupStage", "1");
        sessionStorage.setItem("sessionChecked", "true");
        sessionChecked = true;
      } else {
        sessionChecked = sessionStorage.getItem("sessionChecked");
      }
      setCurrentBrochureId(brochureId);

      if (!sessionChecked) {
        sessionStorage.setItem("sessionChecked", "true");

        const appUrl = `fletushke://brochure/${brochureId}`;
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        if (isFacebookMessenger()) {
          // For Facebook Messenger, show the "Open Browser" option first
          setShowPopup(true);
          sessionStorage.setItem("popupStage", "0");
        } else {
          // For other cases, attempt to open the app directly
          if (isIOS) {
            window.location.href = appUrl;
          } else {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = appUrl;
            document.body.appendChild(iframe);
            setTimeout(() => {
              document.body.removeChild(iframe);
            }, 1000);
          }

          // Show app store popup if app doesn't open after a delay
          setTimeout(() => {
            if (!document.hidden) {
              setShowPopup(true);
              sessionStorage.setItem("popupStage", "1");
            }
          }, 1000);
        }
      } else {
        sessionStorage.setItem("popupStage", "1");
        setShowPopup(true);
      }
    }
  }, []);

  return (
    <Router>
      <div style={{ fontFamily: "Arial, sans-serif" }}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fletushka" element={<Home />} />
          <Route path="/fletushkaonline" element={<Home />} />
          <Route path="/fletushka-online" element={<Home />} />
          <Route path="/fletushke" element={<Home />} />
          <Route path="/fletushkë" element={<Home />} />
          <Route path="/zbritje" element={<Home />} />
          <Route path="/aksion" element={<Home />} />
          <Route path="/Fletushka" element={<Home />} />
          <Route path="/FletushkaOnline" element={<Home />} />
          <Route path="/Fletushkaonline" element={<Home />} />
          <Route path="/Fletushka-online" element={<Home />} />
          <Route path="/Fletushka-Online" element={<Home />} />
          <Route path="/Fletushke" element={<Home />} />
          <Route path="/Fletushkë" element={<Home />} />
          <Route path="/policy" element={<Policy />} />
          <Route
            path="/brochure/:brochureId"
            element={
              <React.Fragment>
                <Home />
                {showPopup && currentBrochureId && (
                  <AppRedirectPopup
                    brochureId={currentBrochureId}
                    onClose={closePopup}
                    onBrowserOpen={handleBrowserOpen}
                  />
                )}
              </React.Fragment>
            }
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
